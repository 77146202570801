// Here you can add other styles
.border-dashed {
  border-style: dashed !important;
}
.card-header .col {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.pagination {
  cursor: pointer;
}

.pagination-sm {
  margin-bottom: 0;
}
